import React from "react";
import Layout from "../components/layout";
import JSONData from "../../content/memories.json";
import { useQueryParam, StringParam } from "use-query-params";

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

const Modal = ({ props, clear }) => {
	// console.log('Modal', props);

	const stopEvent = e => {
		e.stopPropagation();
	};

	const handleKeyPress = (event) => {
		// console.log('Handle key events', event.key, event.keyCode, event);
		if (event.keyCode === 27) {
			clear();
		}
	};

	React.useEffect(() => {
		document.addEventListener('keydown', handleKeyPress);
		return () => document.removeEventListener('keydown', handleKeyPress);
	});

	if (!props) return null;

	return <div className={` ${props.album ? 'modal-wrapper' : 'd-none'} `} onClick={clear} >
		<div id="modalContent" className="modal-content" onClick={e => stopEvent(e)} tabIndex="0" onKeyPress={(e) => handleKeyPress(e)}>
			{/* <div>{props.album}</div> */}
			<div className={`close-modal-btn`} onClick={clear}><i className="fas fa-times-circle"></i></div>
			<AwesomeSlider mobileTouch={true}>
				{props.pictures.map((data, index) => {
					return <div key={`item_${index}`} data-src={data} />
				})}
			</AwesomeSlider>
		</div>
	</div>;
};

const Tile = ({ data }) => {
	const { album, thumbnail } = data;
	// console.log('Tile:', album, thumbnail);
	return <div className={`tile-card`}>
		<img loading="lazy" src={thumbnail} alt={album} className={`tile-img`} />
		<div className={`tile-name`}>{album}</div>
	</div>;
};

export default function Memories() {

	const [album, selAlbum] = useQueryParam('album', StringParam);
	// console.log('QueryParam:', album);

	const clearSelection = () => {
		selAlbum(null);
	};

	const selectedAlbumData = JSONData.albums.filter((data) => data.album === album);

	return (
		<Layout title="Memories">
			<div className="container d-flex justify-content-between flex-wrap" >

				{JSONData.albums.map((data, index) => {
					return <div key={`item_${index}`}
						className={` ${(album === data.album) ? 'tile flex-grow-1 selected' : 'tile flex-grow-1'} `}
						onClick={() => selAlbum(data.album)}><Tile data={data}></Tile></div>
				})}

			</div>

			{selectedAlbumData
				? <Modal props={selectedAlbumData[0]} clear={clearSelection} />
				: null}
		</Layout>
	)
}